import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'
import SEO from '../components/seo';
import Footer from '../components/footer';
import TopicHeader from '../components/topicHeader';
import constants from '../constants';
import Loader from '../components/loader';

// const Wrapper = styled.div`
//   ${tw` bg-gray-500 `}
// `;

const TopicPage = (props) => {
  const topicId = props.location.search.substr(4);

  const [topic, setTopic] = useState({
    loading: true,
    data: {}
  });
  const [error, setError] = useState(false); // when something went wrong, e.g: 500 ERROR

  useEffect(() => {
    (async function(){
      try{
        let resp = await axios.get(`${constants.API_URL}/topics`, {
          params: { id: topicId }
        });
        console.log(resp.data);
        console.log(resp.data)
        setTopic({
          loading: false,
          data: resp.data[0] || {}
        });

      }catch(err){
        setTopic({ loading: false, data: {} });
        setError(true);
      }
    }());
  }, []);
  
  if(topic.loading){
    return (
      <div tw="mx-auto max-w-lg my-24 text-center">
        <Loader width="55px" />
      </div>
    )
  }

  return (
    <div>
      <SEO title="Topic" />
      {
        error ? (
          <div tw="mx-auto max-w-xl my-24">
            <b> Something went wrong, please come back later... </b>
            <Loader width="55px" />
          </div>
        ) : (
          Object.keys(topic.data).length > 0 ? (
            <div>
            <TopicHeader
              img={`${constants.API_URL}${topic.data.icon.url}`}
              name={topic.data.name}
            />
            <div tw="mt-32 mb-60 max-w-3xl mx-auto px-2">
              <ReactMarkdown>
                {topic.data.description}
              </ReactMarkdown>
            </div>
            </div>
          ) : (
            <>
            <div tw="text-center max-w-xl mx-auto my-40 px-2 py-5">
              <p tw="font-medium text-lg"> This topic doesn't exit... </p>
            </div>
            </>
          )
        )
      }

      <Footer />
    </div>
  )
}

export default TopicPage
