import React from 'react';
import tw, {styled} from 'twin.macro';
// import Img from '../images/smoking.png';

const Wrapper = styled.div`
  height: 150px;
  background: #07288e;
  padding: 0.5rem;
`;

const TopicImg = styled.img`
  ${tw`rounded-full w-20 h-20 mx-auto p-2`}
  background: rgba(0, 198, 246, .18);
`;

export default function TopicHeader({ img, name }) {
  return (
    <Wrapper>
      <a href="/" tw="inline-block text-left my-2 px-2 py-1 text-sm bg-gray-100 hover:bg-gray-200 transition text-gray-600 font-medium rounded-full">
        Go back
      </a>
      <div tw="text-center bg-white shadow-lg rounded-lg p-2 max-w-lg mx-auto mt-10">
        <TopicImg src={img} />
        <span tw="block font-medium text-lg text-black"> {name} </span>
      </div>
    </Wrapper>
  )
}
